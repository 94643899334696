import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface HeaderState {
    city: string
    full_address: string
    phone: string
    note: string
    fees: number
}


// Define the initial state using that type
const initialState: HeaderState = {
    city: '',
    full_address: '',
    phone: '',
    note: '',
    fees: 0
}

export const titleSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderInformation: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                city: action.payload.city,
                full_address: action.payload.full_address,
                phone: action.payload.phone,
                note: action.payload.note,
                fees: action.payload.fees,
            };
        },
    }
})

export const { setOrderInformation } = titleSlice.actions


export default titleSlice.reducer
