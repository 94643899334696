import React, { useContext, useState } from 'react'
import LogoBrand from '../../assets/--images/logo-noel.png'
import useCategory from '../../utils/utilities/hooks/useCategory';
import { useQuery } from '@tanstack/react-query';
import { Category } from '../../sdks/category-v1/utils/DataSchemas';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import toast from 'react-hot-toast';
function Footer() {
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  let { client } = useCategory()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(4);
  const navigate = useNavigate()

  const {
    data: categoryData,
    isLoading
  } = useQuery({
    queryKey: ["footer-categories-data", page, limit],
    queryFn: async () => {
      let result = await client.getAllCategories({ page, limit})
      return result;
    }
  });

  const goToProtectedLink = async (link: string) => {
    if(sessionInfo?.userInfo) {
      navigate(link)
       
    }else {
        toast('Veuillez vous connecter !', {
            duration: 4000,
            position: 'top-center',
          
            // Styling
            style: {
              fontSize: 16,
              fontWeight: 700,
              border: `2px solid #ec2535`
            },
            className: '',
          
            // Custom Icon
            icon: '🚨',
          
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: '#000',
              secondary: '#fff',
            },
          
            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
    }
  }

  const goToApp = async () => {
    toast('Bientôt disponible!', {
      duration: 4000,
      position: 'bottom-center',
    
      // Styling
      style: {
        fontSize: 16,
        fontWeight: 700,
        border: `2px solid #0B188BFF`
      },
      className: '',
    
      // Custom Icon
      icon: '🚨',
    
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
    
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });
}
  const iconItem = (slug: string) => {
    switch (slug) {
      case 'ordinateurs':
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
        <path d="M14 2H10C6.72077 2 5.08116 2 3.91891 2.81382C3.48891 3.1149 3.1149 3.48891 2.81382 3.91891C2 5.08116 2 6.72077 2 10C2 13.2792 2 14.9188 2.81382 16.0811C3.1149 16.5111 3.48891 16.8851 3.91891 17.1862C5.08116 18 6.72077 18 10 18H14C17.2792 18 18.9188 18 20.0811 17.1862C20.5111 16.8851 20.8851 16.5111 21.1862 16.0811C22 14.9188 22 13.2792 22 10C22 6.72077 22 5.08116 21.1862 3.91891C20.8851 3.48891 20.5111 3.1149 20.0811 2.81382C18.9188 2 17.2792 2 14 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M11 15H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 22L14.1845 21.5811C13.4733 20.6369 13.2969 19.1944 13.7468 18M9.5 22L9.8155 21.5811C10.5267 20.6369 10.7031 19.1944 10.2532 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M7 22H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
        break;
      case 'imprimantes':
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
        <path d="M7.35396 18C5.23084 18 4.16928 18 3.41349 17.5468C2.91953 17.2506 2.52158 16.8271 2.26475 16.3242C1.87179 15.5547 1.97742 14.5373 2.18868 12.5025C2.36503 10.8039 2.45321 9.95455 2.88684 9.33081C3.17153 8.92129 3.55659 8.58564 4.00797 8.35353C4.69548 8 5.58164 8 7.35396 8H16.646C18.4184 8 19.3045 8 19.992 8.35353C20.4434 8.58564 20.8285 8.92129 21.1132 9.33081C21.5468 9.95455 21.635 10.8039 21.8113 12.5025C22.0226 14.5373 22.1282 15.5547 21.7352 16.3242C21.4784 16.8271 21.0805 17.2506 20.5865 17.5468C19.8307 18 18.7692 18 16.646 18" stroke="currentColor" strokeWidth="1.5" />
        <path d="M17 8V6C17 4.11438 17 3.17157 16.4142 2.58579C15.8284 2 14.8856 2 13 2H11C9.11438 2 8.17157 2 7.58579 2.58579C7 3.17157 7 4.11438 7 6V8" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M13.9887 16L10.0113 16C9.32602 16 8.98337 16 8.69183 16.1089C8.30311 16.254 7.97026 16.536 7.7462 16.9099C7.57815 17.1904 7.49505 17.5511 7.32884 18.2724C7.06913 19.3995 6.93928 19.963 7.02759 20.4149C7.14535 21.0174 7.51237 21.5274 8.02252 21.7974C8.40513 22 8.94052 22 10.0113 22L13.9887 22C15.0595 22 15.5949 22 15.9775 21.7974C16.4876 21.5274 16.8547 21.0174 16.9724 20.4149C17.0607 19.963 16.9309 19.3995 16.6712 18.2724C16.505 17.5511 16.4218 17.1904 16.2538 16.9099C16.0297 16.536 15.6969 16.254 15.3082 16.1089C15.0166 16 14.674 16 13.9887 16Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M18 12H18.009" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
        break;
      case 'accessoires':
          return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
          <path d="M14.5 7H9.5C6.21252 7 4.56878 7 3.46243 7.90796C3.25989 8.07418 3.07418 8.25989 2.90796 8.46243C2 9.56878 2 11.2125 2 14.5C2 17.7875 2 19.4312 2.90796 20.5376C3.07418 20.7401 3.25989 20.9258 3.46243 21.092C4.56878 22 6.21252 22 9.5 22H14.5C17.7875 22 19.4312 22 20.5376 21.092C20.7401 20.9258 20.9258 20.7401 21.092 20.5376C22 19.4312 22 17.7875 22 14.5C22 11.2125 22 9.56878 21.092 8.46243C20.9258 8.25989 20.7401 8.07418 20.5376 7.90796C19.4312 7 17.7875 7 14.5 7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M12 7V5C12 4.44772 12.4477 4 13 4C13.5523 4 14 3.55228 14 3V2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7 12L8 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.5 12L12.5 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 12L17 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7 17L17 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
          break;
      case 'projecteurs':
          return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
          <path d="M11.5 10H6.5C4.62513 10 3.6877 10 3.03054 10.4775C2.8183 10.6317 2.63166 10.8183 2.47746 11.0305C2 11.6877 2 12.6251 2 14.5C2 16.3749 2 17.3123 2.47746 17.9695C2.63166 18.1817 2.8183 18.3683 3.03054 18.5225C3.6877 19 4.62513 19 6.5 19H17.5C19.3749 19 20.3123 19 20.9695 18.5225C21.1817 18.3683 21.3683 18.1817 21.5225 17.9695C22 17.3123 22 16.3749 22 14.5C22 12.6251 22 11.6877 21.5225 11.0305C21.3683 10.8183 21.1817 10.6317 20.9695 10.4775C20.5172 10.1488 19.9321 10.0464 19 10.0145" stroke="currentColor" strokeWidth="1.5" />
          <path d="M5.49981 13.5H5.50879" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="15.5" cy="10" r="3.5" stroke="currentColor" strokeWidth="1.5" />
          <path d="M6.5 19L5 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M17.5 19L19 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M12.5 5L11.5 4M19.5 4L18.5 5M15.5 4V2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
          break;
      default:
        break;
    }
  }
  return (
    <footer className="footer theme-clearfix footer-style5">
      <div className="menu-footer clearfix hidden width650:flex justify-center">
        <div className="wrapper flex items-center justify-center">
              <div id="text-31" className="widget text-31 widget_text pull-left">
                  <div className="widget-inner">			
                    <div className="textwidget">
                      <div className="ya-logo">
                        <a href="./">
                          <img src={LogoBrand} alt="yepia - logo" className='w-[100px] h-auto' />
                          </a>
                      </div>
                    </div>
                  </div>
              </div>
              <div id="nav_menu-11" className="widget nav_menu-11 widget_nav_menu pull-left">
                <div className="widget-inner">
                  <ul id="menu-menu-footer" className="menu">
                    <li className="menu-home furnicom-menu-custom level1">
                      <a href="./" className="item-link">
                        <span className="have-title">
                          <span className="menu-title">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#444444" fill="none">
                              <path d="M8.99944 22L8.74881 18.4911C8.61406 16.6046 10.1082 15 11.9994 15C13.8907 15 15.3848 16.6046 15.2501 18.4911L14.9994 22" stroke="currentColor" strokeWidth="1.5" />
                              <path d="M2.35151 13.2135C1.99849 10.9162 1.82198 9.76763 2.25629 8.74938C2.69059 7.73112 3.65415 7.03443 5.58126 5.64106L7.02111 4.6C9.41841 2.86667 10.6171 2 12.0001 2C13.3832 2 14.5818 2.86667 16.9791 4.6L18.419 5.64106C20.3461 7.03443 21.3097 7.73112 21.744 8.74938C22.1783 9.76763 22.0018 10.9162 21.6487 13.2135L21.3477 15.1724C20.8473 18.4289 20.597 20.0572 19.4291 21.0286C18.2612 22 16.5538 22 13.1389 22H10.8613C7.44646 22 5.73903 22 4.57112 21.0286C3.40321 20.0572 3.15299 18.4289 2.65255 15.1724L2.35151 13.2135Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                          </svg>
                          Accueil
                          </span>
                        </span>
                      </a>
                    </li>

                    {(categoryData && categoryData.results.length) ? <>
                    {
                      categoryData.results.map((cat: Category, index: number) => {
                        return <li key={index} className="menu-living-room furnicom-menu-custom level1">
                        <Link to={`/category/${cat.slug}`} className="item-link">
                          <span className="have-title">
                            
                            <span className="menu-title">
                              {iconItem(cat.slug)}
                              {cat.name}
                            </span>
                          </span>
                        </Link>
                      </li>
                      })
                    }
                    </> : <></>}
                    
                    {/* <li className="menu-contact-us furnicom-menu-custom level1">
                      <a href="./category" className="item-link">
                        <span className="have-title">
                          <span className="menu-title">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                <path d="M2 7H4.86503C5.59174 7 6.28868 6.64031 6.80255 6.00005C7.53643 5.08565 8.62195 4.76636 9.60655 5.17529L11 5.75402M2 15.9668H3.62068C4.78017 15.9668 5.35991 15.9668 5.90812 16.1213C5.93477 16.1288 5.96134 16.1366 5.98782 16.1446C6.53259 16.3101 7.01496 16.6209 7.97971 17.2427C9.86787 18.4596 10.812 19.0681 11.8407 18.994C11.8892 18.9905 11.9376 18.9858 11.9859 18.9801C13.0096 18.8577 13.8119 18.0821 15.4166 16.5308L17 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M22 7.23384H19.4832C18.4174 7.23384 17.6649 6.65468 16.9003 5.88258C16.4051 5.38247 15.7731 5.08772 15.1088 5.04713C14.366 5.00175 13.5053 4.93785 12.784 5.13601C11.9811 5.35656 11.451 5.96063 10.8902 6.59196L9.44309 8.22111C8.8523 8.88621 8.8523 9.96455 9.44309 10.6297C9.953 11.2037 10.7519 11.2928 11.3519 10.8424C11.7837 10.5184 12.2743 9.99506 12.7831 9.83025C13.3557 9.64476 13.7109 10.0615 14.0354 10.4999L16.3556 13.6344C17.2167 14.7976 17.6472 15.3793 18.2358 15.6896C18.8244 16 19.4969 16 20.842 16H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                            Vendre sur Yepia
                          </span>
                        </span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>					
        </div>
      </div>
      <div className="theme-clearfix flex justify-center">
        <div className="wrapper">
          <div className="footer-top">
            <div className="w-ful flex justify-between flex-wrap gap-x-20 gap-y-5 ">
              {/* <div id="nav_menu-3" className="col-lg-3 col-md-3 col-sm-6 widget nav_menu-3 widget_nav_menu">
                <div className="widget-inner">
                    <div className="button-ver-menu">Nos services</div>
                    <ul id="menu-our-services" className="menu">
                      <li className="menu-delivery-information furnicom-menu-custom level1">
                        <a href="#" className="item-link">
                          <span className="have-title">
                            <span className="menu-title">Termes et confidentialités</span>
                          </span>
                        </a>
                      </li>
                      <li className="menu-returns furnicom-menu-custom level1">
                        <a href="#" className="item-link">
                          <span className="have-title">
                            <span className="menu-title">Conditions de vente</span>
                          </span>
                        </a>
                      </li>
                      <li className="menu-terms-conditions furnicom-menu-custom level1">
                        <a href="#" className="item-link">
                          <span className="have-title">
                            <span className="menu-title">Foire aux questions</span>
                          </span>
                        </a>
                      </li>
                      <li className="menu-shipping-refund furnicom-menu-custom level1">
                        <a href="#" className="item-link">
                          <span className="have-title">
                            <span className="menu-title">Livraison et remboursement</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                </div>
              </div>
              <div id="nav_menu-4" className="col-lg-3 col-md-3 col-sm-6 widget nav_menu-4 widget_nav_menu">
                <div className="widget-inner">
                  <div className="button-ver-menu">Extras</div>
                  <ul id="menu-extras" className="menu">
                    <li className="menu-contact-us furnicom-menu-custom level1">
                      <a href="#" className="item-link">
                        <span className="have-title">
                          <span className="menu-title">A propos de nous</span>
                        </span>
                      </a>
                    </li>
                    <li className="menu-returns furnicom-menu-custom level1">
                      <a href="#" className="item-link">
                        <span className="have-title">
                          <span className="menu-title">Contactez-nous</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div id="nav_menu-5" className="col-sm-6 widget nav_menu-5 widget_nav_menu">
                <div className="widget-inner">
                  <div className="button-ver-menu">Mon compte</div>
                  <ul id="menu-my-account-footer" className="menu">
                    <li className="menu-my-orders furnicom-menu-custom level1">
                      <a href="#" 
                      onClick={(e: any) => {
                        e.preventDefault();
                        goToProtectedLink('/profile/orders')
                      }}
                      className="item-link">
                        <span className="have-title">
                          <span className="menu-title">Mes commandes</span>
                        </span>
                      </a>
                    </li>
                    <li className="menu-my-credit-slips furnicom-menu-custom level1">
                      <a href="#" 
                      onClick={(e: any) => {
                        e.preventDefault();
                        goToProtectedLink('/wishlist')
                      }}
                      className="item-link">
                        <span className="have-title">
                          <span className="menu-title">Mes favoris</span>
                        </span>
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div id="text-9" className="col-sm-6 widget text-9 widget_text">
                <div className="widget-inner">
                  <div className="button-ver-menu">Contactez-nous</div>
                  <div className="textwidget">
                    <div className="contact-footer">
                      <ul>
                      <li className='flex gap-x-1 items-start'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                            <path d="M13.6177 21.367C13.1841 21.773 12.6044 22 12.0011 22C11.3978 22 10.8182 21.773 10.3845 21.367C6.41302 17.626 1.09076 13.4469 3.68627 7.37966C5.08963 4.09916 8.45834 2 12.0011 2C15.5439 2 18.9126 4.09916 20.316 7.37966C22.9082 13.4393 17.599 17.6389 13.6177 21.367Z" stroke="currentColor" strokeWidth="1.5" />
                            <path d="M15.5 11C15.5 12.933 13.933 14.5 12 14.5C10.067 14.5 8.5 12.933 8.5 11C8.5 9.067 10.067 7.5 12 7.5C13.933 7.5 15.5 9.067 15.5 11Z" stroke="currentColor" strokeWidth="1.5" />
                          </svg>
                          <span>Côte d’Ivoire, Abidjan,<br />
                          Plateau Immeuble Media – rue du commerce</span>
                        </li>
                        <li className='flex gap-x-1 items-start'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                            <path d="M16 6.5C15.9377 4.78752 15.7251 3.75009 14.9988 3.02513C13.9718 2 12.3188 2 9.01289 2C5.70698 2 4.05403 2 3.02701 3.02513C2 4.05025 2 5.70017 2 9V15C2 18.2998 2 19.9497 3.02701 20.9749C4.05403 22 5.70698 22 9.01289 22C12.3188 22 13.9718 22 14.9988 20.9749C15.7251 20.2499 15.9377 19.2125 16 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M8 19H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 11.9908L16 11.9998M20.0483 16.4912C21.2541 15.3396 22 13.7486 22 11.9912C22 10.2339 21.2541 8.64286 20.0483 7.49121M18 9.74121C18.6029 10.317 18.9759 11.1125 18.9759 11.9912C18.9759 12.8699 18.6029 13.6654 18 14.2412" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M6 2L6.089 2.53402C6.28188 3.69129 6.37832 4.26993 6.77519 4.62204C7.18918 4.98934 7.77614 5 9 5C10.2239 5 10.8108 4.98934 11.2248 4.62204C11.6217 4.26993 11.7181 3.69129 11.911 2.53402L12 2" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                        </svg>
                        (00225) 272-024-3418
                        
                        </li>
                        <li className='flex gap-x-1 items-start'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                              <path d="M6.5 9H8.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M17.5 8V4C17.5 2.89543 18.3954 2 19.5 2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.5 18L12.5 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M17.5 5.15889C16.5351 5 15.2591 5 13.375 5H10.625C7.70671 5 6.24757 5 5.14302 5.59039C4.27088 6.05656 3.55656 6.77088 3.09039 7.64302C2.5 8.74757 2.5 10.2067 2.5 13.125C2.5 14.876 2.5 15.7515 2.85424 16.4142C3.13394 16.9375 3.56253 17.3661 4.08581 17.6458C4.74854 18 5.62403 18 7.375 18H16.625C18.376 18 19.2515 18 19.9142 17.6458C20.4375 17.3661 20.8661 16.9375 21.1458 16.4142C21.5 15.7515 21.5 14.876 21.5 13.125C21.5 10.2067 21.5 8.74757 20.9096 7.64302C20.7356 7.31755 20.5271 7.01406 20.2887 6.73725" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M12.5006 18V11C12.5006 10.071 12.5006 9.60649 12.439 9.21782C12.1002 7.07836 10.4222 5.40041 8.28276 5.06155C8.2009 5.04859 8.11566 5.03835 8.02344 5.03027" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                          </svg>
                          <span >abou.sylla@sntic-ci.net</span>
                        </li>
                      </ul>
                      
                    </div>
                  </div>
                </div>
              </div>					
            </div>
          </div>
          <div className="footer-bottom">
          <div className="grid grid-cols-1 treffPunkTeSize:grid-cols-2">
            <div id="text-11" className="widget widget_text flex flex-col items-cend">
              <div className="widget-inner">
                  <h3><span>Téléchargez nos applications</span></h3>
                  <div className="textwidget"><div className="app-store">
                      <a href="/"
                      className='cursor-pointer'
                      onClick={(e: any) => {
                        e.preventDefault();
                        goToApp()
                      }}
                      ><img src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/apstore.png" title="app store" alt="app store" width={117} height={40} /></a>
                      <a 
                      href="/"
                      className='cursor-pointer'
                      onClick={(e: any) => {
                        e.preventDefault();
                        goToApp()
                      }}
                      ><img src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/googleplay.png" title="app store" alt="app store" width={119} height={40} /></a>
                  </div>
                </div>
              </div>
            </div>
            <div id="text-10" className="widget widget_text ">
              <div className="widget-inner"><h3><span>Suivez nous</span></h3>
                <div className="textwidget"><div className="shop-social">
                    <ul>
                      <li><a href="https://www.facebook.com/share/LRVTwtkGWtgSCKQo/?mibextid=LQQJ4d">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                          </svg>
                        </a>
                      </li>
                      
                      <li><a href="https://www.instagram.com/sntic_cotedivoire?igsh=MTNhNGRybHVqODV2eQ==">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                            <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                            <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" strokeWidth="1.5" />
                            <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a></li>
                      <li><a href="https://www.tiktok.com/@snticcotedivoire?_t=8qZKyoKB1t7&_r=1">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#ffffff" fill="none">
                          <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                          <path d="M10.5359 11.0075C9.71585 10.8916 7.84666 11.0834 6.93011 12.7782C6.01355 14.4729 6.9373 16.2368 7.51374 16.9069C8.08298 17.5338 9.89226 18.721 11.8114 17.5619C12.2871 17.2746 12.8797 17.0603 13.552 14.8153L13.4738 5.98145C13.3441 6.95419 14.4186 9.23575 17.478 9.5057" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>  
                      </a></li>
                      
                    </ul>
                </div>
              </div>
            </div>
          </div>					
          </div>
        </div>

        </div>
      </div>
      <div className="copyright theme-clearfix flex justify-center">
      <div className="wrapper">
          <div className="copyright-text pull-left">
            <p>Copyright © {new Date().getFullYear()} Yepia. Tous droits réservé. Conçu par <a className="mysite" target='_blank' href="https://wa.me/002250787467290">Marx N'Guessan & Team</a>.</p>
          </div>
          
        </div>
      </div>
    </footer>

  )
}

export default Footer