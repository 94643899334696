import useOrder from '../../../../utils/utilities/hooks/useOrder';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react'
import toast from 'react-hot-toast';

function ReturnOrder() {

  let { client } = useOrder()

  const [orderId, setOrder] = useState<string>('')

  const mutation = useMutation({
    mutationFn: async () => {
      return await client.refunRequest(orderId, 'refund-request');
    },
    onSuccess: () => {
      toast.success('Demande enregistrée avec succès !', {position: 'bottom-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #1DC953FF` },})

      setOrder('')
    },
    onError: (data: any) => {
      toast.error(data ? data?.message : `Une erreur est survenue` , {position: 'bottom-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #BF153AFF` },})
    },
  });
  return (
    <form className="EditAccountForm edit-account"  method="post">
    <p className="form-row form-row--first form-row form-row-first min-w-[300px]">
      <label htmlFor="orderId">N° de la commande<span className="required">*</span></label>
      <input type="text" 
      value={orderId}
      onChange={(e: any) => {
        setOrder(e.target.value)
      }}
      className="Input Input--text input-text" name="orderId" />
    </p>
  
  <div className="clear" />
  <p className='p-[3px]'>
    <button type="button" 
    disabled={mutation?.isPending
      ||
      (
        orderId.trim() === ""
      )
    }
    onClick={() => {
      !mutation.isPending && mutation.mutate()
    }}
    className="Button button wp-element-button" name="save_account_details" value="Save changes">
      {!mutation.isPending ? 'Valider': 'Loading...'}
    </button>
  </p>
</form>

  )
}

export default ReturnOrder