import React from 'react'

function ProductFull({image = null}: any) {
  return (
    <div className='--product-full-image' >
       {image && <div>
            <img src={image?.url} className="wp-post-image" />
        </div>}
    </div>
  )
}

export default ProductFull