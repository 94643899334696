import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Stepper from '../../--components/--stepper'
import React, { useEffect, useState } from 'react'
import userUser from '../../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { Riple } from 'react-loading-indicators';
function Activation() {
  const {accessKey} = useParams()

  const [sucess, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const [countdown, setCountdown] = useState<number>(5)
  const [searchParams] = useSearchParams();

  const searchText = searchParams.get("redirectUrl");

  let { client } = userUser()
  const navigate =  useNavigate()
  const mutation = useMutation({
    mutationKey: ['activation-key', accessKey],
    mutationFn: async () => {
      if(accessKey) {
        return await client.activateAccount({
          activation_token: accessKey
        });
      }
    },
    onSuccess: (data, variables, context) => {
      setSuccess(true)
    },
    onError: (data: any) => {
      setError(true)
    },
  });

  useEffect(() => {
    mutation.mutate()
  }, [accessKey])

  useEffect(() => {
      if (countdown <= 0 && searchText) {
        window.location.href = `/${searchText}`
      }

      const timer = setTimeout(() => {
        setCountdown((prev: number) => prev - 1)
      }, 1000);

      return () => clearTimeout(timer);
  }, [countdown, window.location])

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                    {
                      mutation?.isPending ? <Riple color="#fff" size="small" text="Loading..." textColor="" /> : <div className="col-lg-6 col-md-6 col-sm-12">
                      {sucess ? <h2 className='text-blue-800'>Compte activé !</h2>: error ? <h2>Erreur survenu.</h2> : ''}
                      
                      {sucess ? <h3>Votre compte a été activé avec succès. Vous serez redirigé dans {countdown} secondes... </h3>: error ? <p>Une erreur s'est produite ou votre lien a expriré.</p> : ''}
                    </div>
                    }
                      
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default Activation