import { Link } from 'react-router-dom'
import { Product } from '../../sdks/product-v1/utils/DataSchemas'
import React from 'react'
import { moneyFormating } from '../../utils/utilities/constants'

 
function ProductBigStockageCard({product}: any) {
  return (
    <div className="mcategory-item item-product px-3">
      <div className="item-thumb">
        <img width={400} height={'400'}  src={product?.images && product?.images[0]?.url} alt="" />						
        <div className="item-bottom">
              <Link to={`/product/${product?._id}`} data-quantity={1} className="button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart" >Ajouter au panier</Link>
        </div>						
      </div>
      <div className="item-content">
        <h3><Link to={`/category/${product?.category_level_one?.slug}`}>{product?.category_level_one?.name}</Link></h3>
        <h4 className='truncate text-ellipsis'><Link to={`/product/${product?._id}`} >{product?.title}</Link></h4>								
        <div className="item-price">
          <span>
            <span className="-Price-amount amount">{moneyFormating(product?.promotion?.active ? product?.promotion?.cost : product?.price)}</span>							</span>
        </div>
        <div className="reviews-content">
            <div className="star" >
              {Array((Number(product?.ratings ?? 0))).fill(undefined).map((v, i) => i + 1).map((data: any, key: number) => {
                return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#FD0505FF" fill="none">
                    <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              })}
              {Array((5 - Number(product?.ratings ?? 0))).fill(undefined).map((v, i) => i + 1).map((data: any, key: number) => {
                return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#444444" fill="none">
                  <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              })}
            </div>
        </div>	
      </div>			
    </div>
  )
}

export default ProductBigStockageCard