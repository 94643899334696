import { Link, useNavigate } from 'react-router-dom';
import Stepper from '../../--components/--stepper'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/auth';
import { City } from "country-state-city";
import { useDispatch, useSelector } from 'react-redux';
import { moneyFormating } from '../../../utils/utilities/constants';
import { setOrderInformation } from '../../..//utils/redux/features/orderSlice';
import toast from 'react-hot-toast';
function Checkout() {
  const navigate = useNavigate()
  const { sessionInfo, setUserInfo } = useContext(AuthContext)
  const [city, setCity] = useState<string>('Abidjan')
  const [full_address, setFullAdress] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [fees, setFees] = useState<number>(0)
  const dispatch = useDispatch();

  const { cart } = useSelector((state: any) => state.cart);
  useEffect(() => {
    if(!sessionInfo?.userInfo) {
      navigate('/login')
    }else if(!cart?.length) {
      navigate('/')
    }
  }, [sessionInfo])
  


  if(!sessionInfo?.userInfo || !cart?.length) {
    return null
  }

  const totalPrice = cart.reduce(
      (acc: any, item: any) => acc + item.qty * (item.promotion?.active ? item.promotion?.cost : item.price),
      0
  );

    
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
          <Stepper
          active={3}
          />
          <form name="checkout" method="post" className="checkout" action="" encType="multipart/form-data" >
              <div className="col2-set" id="customer_details">
                <div className="col-1">
                  <div className="billing-fields">
                    <h3>Billing details</h3>
                    <div className="billing-fields__field-wrapper">
                        <p className="form-row form-row-wide address-field update_totals_on_change validate-required" id="billing_country_field" data-priority={40}>
                          <label htmlFor="billing_country" className=''>Ville&nbsp;
                            <abbr className="required" title="required">*</abbr>
                          </label>
                          
                          <span className="input-wrapper">
                            
                            <select name="billing_country" id="billing_country" 
                            className="country_to_state country_select select2-hidden-accessible" 
                            autoComplete="country" 
                            data-placeholder="Selectionner la region…" 
                            data-label="Ville"
                            value={city}
                            onChange={(e: any) => {
                              setCity(e.target.value)
                              if(e.target.value !== 'Abidjan' || e.target.value !== 'Abobo' ) {

                                if( e.target.value === 'Anyama')
                                setFees(2000)
                                else  setFees(6000)
                              }
                            }}
                            >
                              <option value=''>Selectionner la ville… </option>
                              {(City && City.getCitiesOfCountry("CI")?.length) &&
                              City.getCitiesOfCountry("CI")?.map((item) => (
                                <option key={item.stateCode} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </span>
                        </p>
                        <p className="form-row address-field validate-required form-row-wide" id="billing_address_1_field" data-priority={50}>
                          <label htmlFor="billing_address_1" className=''>Adresse de livraison&nbsp;<abbr className="required" title="required">*</abbr></label>
                          <span className="input-wrapper">
                            <input 
                            value={full_address}
                            onChange={(e: any) => setFullAdress(e.target.value)}
                            type="text" className="input-text " 
                            name="billing_address_1" id="billing_address_1" 
                            placeholder="Adresse complète" autoComplete='off'/>
                          </span>
                        </p>
                          
                          <p className="form-row form-row-wide validate-required validate-phone" id="billing_phone_field" data-priority={100}>
                            <label htmlFor="billing_phone" className=''>Télephone&nbsp;  {!sessionInfo?.userInfo?.phone_numbers?.length && <abbr className="required" title="required">*</abbr>}</label>
                            {sessionInfo?.userInfo?.phone_numbers?.length && <span className="input-wrapper">
                              <input type="tel" className="input-text opacity-50" readOnly name="billing_phone" id="billing_phone" value={sessionInfo?.userInfo?.phone_numbers && sessionInfo?.userInfo?.phone_numbers[0]} autoComplete="tel" />
                            </span>}
                          </p>
                          <p className="form-row address-field form-row-wide" id="billing_address_2_field" data-priority={60}>
                            <label htmlFor="billing_address_2" className="screen-reader-text">Second numéro de téléphone.&nbsp;<span className="optional">(optionnel)</span></label>
                            <span className="input-wrapper">
                              <input 
                              value={phone}
                              onChange={(e: any) => setPhone(e.target.value)}
                              type="tel" className="input-text " name="tel-2" id="tel_2" placeholder={sessionInfo?.userInfo?.phone_numbers?.length ?"Second numéro de téléphone (optionnel)" : ""} autoComplete="off" />
                            </span>
                          </p>
                          <a href="javascript:void(Tawk_API.toggle())" className='text-[15px] font-medium text-[#e13035] '> Besoin d'aide ? </a>
                      </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="shipping-fields">
                  </div>
                  <div className="additional-fields">
                    <h3>Information aditionnel</h3>
                    <div className="additional-fields__field-wrapper">
                      <p className="form-row notes" id="order_comments_field" data-priority><label htmlFor="order_comments" className=''>Information personnel à ajouter&nbsp;<span className="optional">(optionnel)</span></label><span className="input-wrapper">
                        <textarea 
                        value={note}
                        onChange={(e: any) => setNote(e.target.value)}
                        name="order_comments" className="input-text resize-none" id="order_comments" placeholder="e.g. commentaire spécial sur ma commande." rows={2} cols={5} defaultValue={""} /></span></p>					</div>
                  </div>
                </div>
              </div>
              <div className="">
                <div id="order_review" className="checkout-review-order">
                  <h3 id="order_review_heading">Votre commande</h3>
                  <table className="shop_table checkout-review-order-table">
                    <thead>
                      <tr>
                        <th className="product-name">Produit</th>
                        <th className="product-total">Sous total</th>
                      </tr>
                    </thead>
                    <tbody>
                    {cart?.map((c: any, index: number) => {
                      return <tr key={index} className="cart_item">
                              <td className="product-name flex">
                                <p className='max-w-[200px] width500:max-w-[300px] bigTablet:max-w-[600px] truncate text-ellipsis'>{c?.title}&nbsp;</p> <strong className="product-quantity">×&nbsp;{c?.qty}</strong>
                              </td>
                              <td className="product-total"> <span className="Price-amount amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span></td>
                            </tr>
                          })
                      }
                    </tbody>
                    <tfoot>
                      <tr className="cart-subtotal">
                        <th>Sous total</th>
                        <td><span className="Price-amount amount">{moneyFormating(totalPrice)}</span></td>
                      </tr>
                      <tr className="cart-subtotal">
                        <th>Frais de livraison</th>
                        <td><span className="Price-amount amount">{moneyFormating(fees)}</span></td>
                      </tr>
                      <tr className="order-total">
                        <th>Total</th>
                        <td><strong><span className="Price-amount amount">{moneyFormating(totalPrice + fees)}</span></strong> </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div id="payment" className="checkout-payment">
                    <div className="form-row place-order">
                      <button type="button"
                      
                      onClick={(e: any) => {
                        e.preventDefault();

                        if(
                          city.trim() === "" ||
                          full_address.trim() === "" ||
                          (!sessionInfo?.userInfo?.phone_numbers?.length && phone.trim() === "")
                        ){
                          toast.error('Veuillez remplir tous les champs nécessaires', {style: {fontSize: 16, fontWeight: 700, border: `2px solid #BF153AFF` },})
                        }else {
                          dispatch(setOrderInformation({
                            city,
                            full_address,
                            phone,
                            note,
                            fees
                          }))

                          setTimeout(() => {
                            navigate('/order-resume')
                          }, 200);
                        }
                        
                      }}
                      className="button alt wp-element-button" id="place_order">Passer au paiement</button>
                      </div>
                  </div>
                </div>
              </div>
          </form>
        </div>
        
      </div>
  )
}

export default Checkout