import useWindowSize from '../../utils/utilities/useWindowSize'
import React, { ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface SubmenuItemProps {
  name: string
  slug: string
}

interface MenuItemProps {
  title: string
  icon: ReactNode
  link: string
  slug: string
  items : Array<SubmenuItemProps>
  category_level_three: Array<SubmenuItemProps>
  setOpenMobileMenu?: Function
}
function MenuItem({title, icon, link, items, category_level_three, setOpenMobileMenu}: MenuItemProps) {
  const navigate = useNavigate()

  const [windowWidth, _] = useWindowSize()
  return (
    <div 
    onClick={(e: any) => {
      if(setOpenMobileMenu) {
        setOpenMobileMenu(false)
      }
      e.stopPropagation()
      navigate(link)
    }} className='--menu-item relative z-[100] ' >
      <div className="flex items-center gap-x-[5px] --menu ">
        {icon}
          <span> {title} </span>
      </div>

      {windowWidth > 649 && <div className="absolute top-0 left-0 w-full z-[-1] ">
        <div className="--menu-item-dropdown mt-[40px]">
          {items.map((cat: SubmenuItemProps, index: number) => {
             let subData: any[] = []
             if(cat.slug === 'ordinateurs-bureaux') {
              subData = category_level_three.slice(0, 4)
            }else if(cat.slug === 'ordinateurs-portables'){
              subData = category_level_three.slice(4, 8)
            }
            return <div className="-menu-item-dropdown-item" key={index}>
            <button 
           onClick={(e: any) => {
            e.stopPropagation()
            navigate('/category/level-two/' + cat.slug)
          }}
            >{cat.name}</button>

            {subData.length ? <ul>
              
              {subData.map((subCat: SubmenuItemProps, key: number) => {
                return <li key={key}>
                  <Link 
                  onClick={(e: any) => {
                    e.stopPropagation()
                  }}
                  to={'/category/level-three/' + subCat.slug}>
                  {subCat.name}
                  </Link>
                </li>
              })}
            </ul>: <></>}

          </div>
          })}
        </div>
      </div>}

      {windowWidth <= 649 && <div className="--menu-item-dropdown mt-[10px]">
          {items.map((cat: SubmenuItemProps, index: number) => {
             let subData: any[] = []
             if(cat.slug === 'ordinateurs-bureaux') {
              subData = category_level_three.slice(0, 4)
            }else if(cat.slug === 'ordinateurs-portables'){
              subData = category_level_three.slice(4, 8)
            }
            return <div className="-menu-item-dropdown-item" key={index}>
            <button 
            onClick={(e: any) => {
              if(setOpenMobileMenu) {
                setOpenMobileMenu(false)
              }
              e.stopPropagation()
              navigate('/category/level-two/' + cat.slug)
            }}
            >{cat.name}</button>

            {subData.length ? <ul>
              
              {subData.map((subCat: SubmenuItemProps, key: number) => {
                return <li key={key} >
                  <Link 
                  onClick={(e: any) => {
                    if(setOpenMobileMenu) {
                      setOpenMobileMenu(false)
                    }
                    e.stopPropagation()
                  }}
                  to={'/category/level-three/' + subCat.slug}>
                  {subCat.name}
                  </Link>
                </li>
              })}
            </ul>: <></>}

          </div>
          })}
        </div>}
      
    </div>
  )
}

export default MenuItem