import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import useProduct from '../../../../utils/utilities/hooks/useProduct'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../../../../sdks/product-v1/utils/DataSchemas'
import { Link } from 'react-router-dom'
import { moneyFormating } from '../../../../utils/utilities/constants'
import { useCountdown } from '../../../../layouts/--components/--countdown-time'
const SliderItem = (product: Product) => {
  const [days, hours, minutes, seconds] = useCountdown(product.promotion.end_dt);

  return <div className="--banners-side-slide">
              <div className="item-img">
                <Link to={`/product/${product?._id}`} className='flex justify-center' ><img width={200} src={product?.images && product?.images[0]?.url}  alt="" decoding="async" loading="lazy" /></Link>
                {product?.promotion?.active && <div className="product-countdown" >
                  <span className="countdown-row countdown-show4">
                    <span className="countdown-section days">
                      <span className="countdown-amount">{days}</span>
                      <span className="countdown-period">{days > 1 ? 'Jours': 'Jour'}</span>
                    </span>
                    <span className="countdown-section hours">
                      <span className="countdown-amount">{hours}</span>
                      <span className="countdown-period">{hours > 1 ? 'Heures': 'Heure'}</span>
                    </span>
                    <span className="countdown-section mins">
                      <span className="countdown-amount">{minutes}</span>
                      <span className="countdown-period">{minutes > 1 ? 'mins': 'min'}</span>
                    </span>
                    <span className="countdown-section secs">
                      <span className="countdown-amount">{seconds}</span>
                      <span className="countdown-period">{seconds > 1 ? 'secs': 'sec'}</span>
                    </span>
                  </span>
                </div>}			
              </div>

              <h3 className='truncate text-ellipsis'><Link to={`/product/${product?._id}`} >{product?.title}</Link></h3>
              
              <div className="item-price">
                  <span>
                  {product?.promotion?.active && <del aria-hidden="true"><span className="-Price-amount amount"><bdi>{moneyFormating(product?.price)}</bdi></span></del>}
                  <ins><span className="-Price-amount amount"><bdi>{moneyFormating(product?.promotion?.active ? product?.promotion?.cost : product?.price)}</bdi></span></ins>
                  </span>
              </div>

              <div className="reviews-content">
                  <div className="star" >
                  {Array((Number(product?.ratings ?? 0))).fill(undefined).map((v, i) => i + 1).map((data: any, key: number) => {
                      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#FD0505FF" fill="none">
                          <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  })}
                  {Array((5 - Number(product?.ratings ?? 0))).fill(undefined).map((v, i) => i + 1).map((data: any, key: number) => {
                      return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#444444" fill="none">
                      <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  })}
                  </div>
              </div>
            </div>
}

function BannerSlide() {

  let { client } = useProduct()
  

  const [page, setPage] = useState(1);

  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ["most-cheaper-products-data", page],
    queryFn: async () => {
      const data = await client.getMostPromotedAndCheaperProducts({ page, limit: 5})
      return data.results
    }
  });
  const targetDate = '2024–11–27T00:00:00';
  return (
    <div className="--banners-side">
            
            {(productData && productData?.length) ? <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 3001 },
                items: 1,
              },
              desktop: {
                breakpoint: { max: 3000, min: 1025 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1024, min: 465 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              },
            }}
            showDots={false}
            swipeable={true}
            draggable={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            partialVisible={false}
          >

            {productData.map((product: Product, key: number) => {
              
              return <SliderItem 
              key={key}
              {...product}
              />
            })}


          </Carousel>: <></>}
          </div>
  )
}

export default BannerSlide