import useWindowSize from '../../utils/utilities/useWindowSize';
import { removeFromCart } from '../../utils/redux/features/cartSlice';
import { moneyFormating } from '../../utils/utilities/constants';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

function HeaderCart() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [windowWidth, _] = useWindowSize()

    const { cart } = useSelector((state: any) => state.cart);

    const totalPrice = cart.reduce(
        (acc: any, item: any) => acc + item.qty * (item.promotion?.active ? item.promotion?.cost : item.price),
        0
      );

      const removeFromCartHandler = (data: any) => {
        dispatch(removeFromCart(data?._id));
      };
  return (
    <div className="--cart-side relative"
    onClick={() => {
        if(windowWidth <= 768) {
            navigate('/cart')
        }
    }}
    > 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
            <path d="M3 2H4.30116C5.48672 2 6.0795 2 6.4814 2.37142C6.88331 2.74285 6.96165 3.36307 7.11834 4.60351L8.24573 13.5287C8.45464 15.1826 8.5591 16.0095 9.09497 16.5048C9.63085 17 10.4212 17 12.002 17H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <circle cx="11.5" cy="19.5" r="1.5" stroke="currentColor" strokeWidth="1.5" />
            <circle cx="18.5" cy="19.5" r="1.5" stroke="currentColor" strokeWidth="1.5" />
            <path d="M18 14H16C14.1144 14 13.1716 14 12.5858 13.4142C12 12.8284 12 11.8856 12 10V8C12 6.11438 12 5.17157 12.5858 4.58579C13.1716 4 14.1144 4 16 4H18C19.8856 4 20.8284 4 21.4142 4.58579C22 5.17157 22 6.11438 22 8V10C22 11.8856 22 12.8284 21.4142 13.4142C20.8284 14 19.8856 14 18 14Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 7L17.5 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        {cart?.length ? <div className="--cart-count flex items-center justify-center absolute top-[-6px] right-[-8px] size-[20px] rounded-full ">{cart.length > 9 ? '+9': cart.length}</div>: <></>}

        {(windowWidth > 768 && cart?.length) ? <div className="--mini-cart-wrapp">
            <div className="--minicart-padding">
                <div className="--minicart-title">
                    <span>Produit</span>
                    <span className="--price-title">Prix</span>
                </div>

                <ul className="--minicart-content">
                    {
                        cart?.map((c: any, index: number) => {
                            return <li key={index} >
                            <div className="--minicart-img">
                            <Link to={`/product/${c?._id}`} className="product-image">
                                <img width={60} height={60} src={c?.images && c?.images[0]?.url} className="attachment-70x60 size-70x60" alt="" decoding="async" loading="lazy" />	</Link>
                            <div className="--product-action">
                                <a href=""
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    removeFromCartHandler(c)
                                }}
                                className="--btn-remove" title="Remove this item"><span /></a>						</div>
                            </div>	 
                            <div className="--detail-item">
                                <div className="--product-details">
                                    <div className="--product-information"> 
                                    <div className="--product-name truncate text-ellipsis">
                                        <Link to={`/product/${c?._id}`} >{c?.title}</Link>	  		
                                    </div>
                                    <div className="--qty">
                                        <span className="--qty-label">Quantité: </span>
                                        <span className="--qty-number">{c?.qty}</span>								</div>
                                    </div>
                                    <div className="--product-price">
                                    <span className="--price"><span className="---Price-amount --amount" >{moneyFormating(c?.promotion?.active ? c?.promotion?.cost : c?.price)}</span></span>		        		        		    		
                                    </div>
                                </div>	
                            </div>			
                        </li>
                        })
                    }
                </ul>

                <div className="--cart-checkout">
                    <div className="--price-total">
                        <span className="--label-price-total">Total:</span>
                        <span className="--price-total-w"><span className="--price"><span className="---Price-amount --amount" >{moneyFormating(totalPrice)}</span></span></span>
                    </div>
                    <div className="--cart-links">
                        <div className="--cart-link"><Link to="/cart" title="Cart">Aller au panier</Link></div>
                    </div>
                </div>

            </div>
        </div> : <></>}
    </div>
  )
}

export default HeaderCart