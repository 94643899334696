import React from 'react'
import Carousel from 'react-multi-carousel'
function ProductThumbsSlider({images = [], setActiveImage = () => {}}: any) {
  return (
    <div className='--product-thumbs-slider'>
        {images?.length  > 1 ? <Carousel
            responsive={{
              mobile: {
                breakpoint: { max: 4000, min: 0 },
                items: 4,
              },
            }}
            showDots={false}
            infinite
            swipeable={true}
            draggable={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            partialVisible={false}
            sliderClass='gap-x-5'
          >
            {images?.map((im: any, key: number) => {
                return <div key={key} 
                onClick={() => setActiveImage(im)}
                className="item-thumbnail-product cursor-pointer">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src={im?.url} className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            })}

        </Carousel>: <></>}
    </div>
  )
}

export default ProductThumbsSlider