import React, { useEffect, useState } from 'react'
import SideBestSeller from '../../../--partials/--best-seller'
import SideCategories from '../../../--partials/--side-categories'
import SectionTitle from '../../../--partials/--section-title'
import Filter from '../../../--partials/--filter'
import ProductBigCardTwo from '../../../--components/--product-big-card-two'
import { useInfiniteQuery } from '@tanstack/react-query'
import useProduct from '../../../../utils/utilities/hooks/useProduct'
import { useParams } from 'react-router-dom'
import EmptyIllustration from '../../../../layouts/--partials/--empty-illustration'

function CategoryLevelThree() {

  let { client } = useProduct()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(20);
  const [meta, setMeta] = useState<any> (null)
  const [id, setId] = useState<string> ('')
  const [categoryName, setCategoryName] = useState<any> (null)
  const [productData, setProductData] = useState<any[]> ([])
  const {slug} = useParams()

  const {  data, isLoading, isFetching, isError, fetchNextPage, hasNextPage}: any =
  useInfiniteQuery({
        queryKey: ['full-sub-category-products-data', page, limit, id],
        queryFn: async ({ pageParam}: any) => {
            
             if(id) {
              const data = await client.getProductsByComplementCategory({ page: pageParam, limit}, id)

              setMeta({
                hasNextPage : data?.hasNextPage,
                hasPrevPage : data?.hasPrevPage,
                limit : data?.limit,
                nextPage : data?.nextPage,
                page : data?.page,
                pagingCounter : data?.pagingCounter,
                prevPage : data?.prevPage,
                totalDocs : data?.totalDocs,
                totalPages : data?.totalPages
              })

              setProductData([...productData, ...data.results])
              if(data.results.length) {
                setCategoryName(data.results[0].category_level_three.name)
              }else setCategoryName(id)
              return data.results
             }
        },
        initialPageParam: 1,
        getNextPageParam(lastPage: any, allPages: any) {
          return lastPage?.length > 0 ? allPages?.length + 1 : undefined;
        },
  })

  useEffect(() => {
    setProductData([])
    setPage(1)
    if(slug) {
      setId(slug)
    }
  }, [slug])
  return (
    <div className='--product-details w-full flex justify-center' >
    <div className="wrapper flex flex-col-reverse width650:flex-row gap-x-[25px] ">
      <div className="--product-details--left-section block width500:flex justify-center gap-x-5 width650:block">
        <SideCategories/>
        <SideBestSeller/>
      </div>

      <div className="--product-details--right-section">

      <div className="flex justify-center w-full --best-seller-products-list">
      <div className="wrapper-inner w-full">
      <div className="w-full flex flex-col smallLaptop:flex-row items-start smallLaptop:justify-between">
              <SectionTitle
                title={<> Categorie: {categoryName && <span><em>{categoryName}</em></span>} </>}
                />
                <Filter />
            </div>
            {(data && productData.length) ? <div className="w-full mt-3 bg-[white] rounded-[5px] p-[15px] grid grid-cols-1 smallScreen:grid-cols-2 width650:grid-cols-1 smallLaptop:grid-cols-2 gap-x-[10px] bigLaptop:gap-x-[25px] gap-y-[20px] ">
                {
                    productData.map((item: any, key: number) => {
                      return <ProductBigCardTwo
                      key={key}
                    product={item}
                    />
                    })
                  }
                  
                </div>: <EmptyIllustration/>}
            
                <div className="flex w-full items-center justify-center --load-more">
                    {meta?.hasNextPage && <button type='button' className="button"
                    onClick={(e: any) => {
                      e.preventDefault()
                      fetchNextPage()
                    }}
                    >
                    {isFetching ? 'Chargement...' : 'Chargez plus '} 
                    </button >}
                  </div>
        </div>
      </div>
      </div>
    </div>
    
</div>
  )
}

export default CategoryLevelThree