import Carousel from 'react-multi-carousel'
import ProductBigCard from '../--components/--product-big-card-two'
import SectionTitle from './--section-title'
import React, { useEffect, useState } from 'react'
import useProduct from '../../utils/utilities/hooks/useProduct'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../../sdks/product-v1/utils/DataSchemas'

function RelatedProductsList({currentData}: any) {
  const [categoryId, setCategoryId] = useState<string>('')
  let { client } = useProduct()
  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ['product--related-data', categoryId],
    queryFn: async () => {
        if(categoryId) {
          const data = await client.getProductsByCategory({ page: 1, limit: 10}, categoryId)
          return data?.results
        }
    }
  })
  useEffect(() => {
    if(currentData) {
      setCategoryId(currentData?.category_level_one?.slug)
    }
  }, [currentData])
  return (
    <div className="flex mb-5 justify-center w-full --best-seller-products-list">
        {(productData && productData.length) ? <div className="wrapper-inner w-full">
          <SectionTitle
          title={<> Vous <span>aimeriez</span> aussi </>}
          />

            <div className="w-full bg-[white] rounded-[5px] p-[15px] grid grid-cols-1 smallScreen:grid-cols-2 width650:grid-cols-1 smallLaptop:grid-cols-2 gap-x-[25px] gap-y-[20px] ">
              {productData.map((item: Product, key: number) => {
                  return <ProductBigCard  key={key} product={item}/>
                })}
            </div>
        </div>: <></>}
      </div>
  )
}

export default RelatedProductsList