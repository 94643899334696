import React, { useContext, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Link, useNavigate } from 'react-router-dom'
import Informations from './----informations'
import Orders from './----orders'
import PersonnalsInformations from './----informations/----persornals-informations'
import PassInformations from './----informations/----pass-informations'
import OrderDetails from './----orders/----order-details'
import { AuthContext } from '../../../context/auth'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import ReturnOrder from './----return-order/----index'
function Profile() {
  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate()
  const logoutHandler = () => {
    mutation.mutate()
  };

  const mutation = useMutation({
    mutationFn: async () => {
      return await signOut();
      
    },
    onSuccess: () => {
      toast("Triste de vous voir déconnecté !", {
        duration: 4000,
        position: 'top-center',
      
        // Styling
        style: {
          fontSize: 16,
          fontWeight: 700,
          border: `2px solid #ec2535`
        },
        className: '',
      
        // Custom Icon
        icon: '🥺',
      
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      setTimeout(() => {
          navigate('/login')
      }, 500);
    },
    onError: (data: any) => {
      toast.error(data ? data?.message : `Une erreur est survenue` , {position: 'bottom-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #BF153AFF` },})
    },
  });

  useEffect(() => {
    if(!sessionInfo?.userInfo) {
      navigate('/')
    }
  }, [sessionInfo])

  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
      <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
        <div className="post-300 page type-page status-publish hentry">
          <header>
            <h2 className="entry-title">Mon compte</h2>
          </header>
          <div className="entry-content">
            <div className="woocommerce">
              <nav className="woocommerce-MyAccount-navigation">
                <ul>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard">
                    <Link to="/profile" className={`${pathname === '/profile' ? 'text-[#ec2535] font-bold' : '' }`}>Profile</Link>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders">
                    <Link to="/profile/orders" className={`${pathname.includes('/profile/orders') ? 'text-[#ec2535] font-bold' : '' }`}>Commandes</Link>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders">
                    <Link to="/profile/return" className={`${pathname.includes('/profile/return') ? 'text-[#ec2535] font-bold' : '' }`}>Retourner une commande</Link>
                  </li>
                  {/* <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account">
                    <Link to="/profile/edit-account" className={`${pathname === '/profile/edit-account' ? 'text-[#ec2535] font-bold' : '' }`}>Mes informations</Link>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account">
                    <Link to="/profile/edit-password" className={`${pathname === '/profile/edit-password' ? 'text-[#ec2535] font-bold' : '' }`}>Changer mon mot de passe</Link>
                  </li> */}
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--customer-logout">
                    <a href=""
                    onClick={(e: any) => {
                      e.preventDefault()
                      logoutHandler()
                    }}
                    >Déconnexion</a>
                  </li>
                </ul>
              </nav>
              <div className="woocommerce-MyAccount-content">
                <div className="woocommerce-notices-wrapper" />

                <Routes>
                  <Route path='' element={<Informations />} />
                  <Route path='orders' element={<Orders />} />
                  <Route path='return' element={<ReturnOrder />} />
                  <Route path='orders/:id' element={<OrderDetails />} />
                  {/* <Route path='edit-account' element={<PersonnalsInformations />} />
                  <Route path='edit-password' element={<PassInformations />} /> */}
                  <Route path={'*'} element={<Navigate replace to={'/'} />} />
                </Routes>
                
              </div>
            </div>
          </div>
          <div className="comment-single">
          </div>	
    
        </div>
      </div>

    </div>
    </div>
  )
}

export default Profile