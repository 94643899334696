import React, { useEffect, useRef } from 'react';
import Header from './layouts/--partials/--header';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from './layouts/--pages/---home';
import Footer from './layouts/--partials/--footer';
import Newsletter from './layouts/--partials/--newsletter';
import ProductDetails from './layouts/--pages/---product-details';
import CategoriesPage from './layouts/--pages/---categories';
import SearchPage from './layouts/--pages/---search';
import Cart from './layouts/--pages/---cart';
import Chekout from './layouts/--pages/---checkout';
import OrderResume from './layouts/--pages/---order-resume';
import Login from './layouts/--pages/---login';
import Register from './layouts/--pages/---register';
import PassForgot from './layouts/--pages/---pass-forgot';
import PassChange from './layouts/--pages/---pass-change';
import Profile from './layouts/--pages/---profile';
import Wishlist from './layouts/--pages/---wishlist';
import AuthProvider, {
  AuthIsSignedIn
} from './context/auth'
import Activation from './layouts/--pages/---activation';
import ReportSva from './layouts/--pages/---report-sva';
function App() {
  const headerRef: any = useRef(null)
  const {pathname} = useLocation()
  useEffect(() => {
    if (headerRef) {
      headerRef?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [pathname])
  return (
    <main ref={headerRef}>
    <AuthProvider>
      <Header/>
      <Routes>
        < Route path={'/*'} element={ <Home /> } />
        < Route path={'/product/:slug'} element={ <ProductDetails /> } />
        < Route path={'/category/*'} element={ <CategoriesPage /> } />
        < Route path={'/search/*'} element={ <SearchPage /> } />
        < Route path={'/cart/*'} element={ <Cart /> } />
        < Route path={'/wishlist/*'} element={ <AuthIsSignedIn><Wishlist /></AuthIsSignedIn> } />
        < Route path={'/checkout/*'} element={ <AuthIsSignedIn><Chekout /></AuthIsSignedIn> } />
        < Route path={'/order-resume/*'} element={ <AuthIsSignedIn><OrderResume /></AuthIsSignedIn> } />
        < Route path={'/login/*'} element={ <Login /> } />
        < Route path={'/activation/:accessKey/*'} element={ <Activation /> } />
        < Route path={'/register/*'} element={ <Register /> } />
        < Route path={'/report-sva/*'} element={ <ReportSva /> } />
        < Route path={'/pass-forgot/*'} element={ <PassForgot /> } />
        < Route path={'/pass-change/:accessKey'} element={ <PassChange /> } />
        < Route path={'/profile/*'} element={ <AuthIsSignedIn><Profile /> </AuthIsSignedIn>} />
      </Routes>
      <Newsletter/>
      <Footer/>
    </AuthProvider>
    </main>
  );
}

export default App;
