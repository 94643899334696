import ProductBigCard from '../../../--components/--product-big-card'
import SectionTitle from '../../../--partials/--section-title'
import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import useProduct from '../../../../utils/utilities/hooks/useProduct'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../../../../sdks/product-v1/utils/DataSchemas'
function NewArrivalsProductsList() {
  let { client } = useProduct()

  const [page, setPage] = useState(1);

  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ["new-arrivals-products-data", page],
    queryFn: async () => {
      const data = await client.getNewArrivalsProducts({ page, limit: 6})
      return data.results
    }
  });
  return (
      <div className="flex justify-center w-full --best-seller-products-list">
          <div className="wrapper">
            <SectionTitle
            title={<> <span>Arrivage</span> (Dernières nouveautés) </>}
            />
             
            {(productData && productData?.length) ? <div className="w-full grid grid-cols-1 width650:grid-cols-2 width1100:grid-cols-3 gap-x-[25px] gap-y-[20px] ">
              {productData.map((item: Product, key: number) => {
                  return <ProductBigCard  key={key} product={item}/>
                })}
            </div>: <></>}
          </div>
        </div>
  )
}

export default NewArrivalsProductsList