import BigStockPuBanner from '../../../--partials/--big-stock-pub-banner'
import ProductBigStockageCard from '../../../--components/--product-big-stockage-card'
import SectionTitle from '../../../--partials/--section-title'
import useProduct from '../../../../utils/utilities/hooks/useProduct';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import useWindowSize from '../../../../utils/utilities/useWindowSize';
import useBanner from '../../../../utils/utilities/hooks/useBanner';
function BigStockProductsList() {
  
  let { client } = useProduct()
  let { client : bannerClient } = useBanner()

  const [page, setPage] = useState(1);
  const [windowWidth, _] = useWindowSize()

  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ["big-stock-products-data", page, windowWidth],
    queryFn: async () => {
      

      if(windowWidth > 991) {
        let returnData: any = [];
        const [bannerResult, prductResult] = await Promise.all([
          bannerClient.getAllBanners({ page, limit: 7}),
          client.getBigStockageProducts({ page, limit: 12})
        ])

        if(bannerResult.results.length && prductResult.results.length) {
          const banner_1 = bannerResult.results.find((banner: any) => banner.position === 'stock_1');
          const banner_2 = bannerResult.results.find((banner: any) => banner.position === 'stock_2');
          const banner_3 = bannerResult.results.find((banner: any) => banner.position === 'stock_3');
          const banner_4 = bannerResult.results.find((banner: any) => banner.position === 'stock_4');

          returnData = [...prductResult.results]
          returnData.splice(0, 0, banner_1);
          returnData.splice(4, 0, banner_2);
          returnData.splice(8, 0, banner_3);
          returnData.splice(12, 0, banner_4);

          return returnData.reduce((acc: any, _: any, i: number) => {
            if (i % 4 === 0) acc.push(returnData.slice(i, i + 4));
            return acc;
          }, []); 
        }else return []
      }else if(windowWidth > 569) {
        let returnData: any = [];
        const [bannerResult, prductResult] = await Promise.all([
          bannerClient.getAllBanners({ page, limit: 7}),
          client.getBigStockageProducts({ page, limit: 8})
        ])

        if(bannerResult.results.length && prductResult.results.length) {
          const banner_1 = bannerResult.results.find((banner: any) => banner.position === 'stock_1');
          const banner_2 = bannerResult.results.find((banner: any) => banner.position === 'stock_2');
          const banner_3 = bannerResult.results.find((banner: any) => banner.position === 'stock_3');
          const banner_4 = bannerResult.results.find((banner: any) => banner.position === 'stock_4');

          returnData = [...prductResult.results]
          returnData.splice(0, 0, banner_1);
          returnData.splice(4, 0, banner_2);
          returnData.splice(6, 0, banner_3);
          returnData.splice(11, 0, banner_4);

          return returnData
        }else return []
      }else {
        let returnData: any = [];
        const [bannerResult, prductResult] = await Promise.all([
          bannerClient.getAllBanners({ page, limit: 7}),
          client.getBigStockageProducts({ page, limit: 6})
        ])

        if(bannerResult.results.length && prductResult.results.length) {
          const banner_1 = bannerResult.results.find((banner: any) => banner.position === 'stock_1');
          const banner_2 = bannerResult.results.find((banner: any) => banner.position === 'stock_2');
          const banner_3 = bannerResult.results.find((banner: any) => banner.position === 'stock_3');
          const banner_4 = bannerResult.results.find((banner: any) => banner.position === 'stock_4');
  
          returnData = [...prductResult.results]
          returnData.splice(0, 0, banner_1);
          returnData.splice(3, 0, banner_2);
          returnData.splice(6, 0, banner_3);
          returnData.splice(9, 0, banner_4);
  
          return returnData
        }else return []

        
      }
    }
  });
  return (
      <div className="flex justify-center w-full --big-stock-products-list">
          <div className="wrapper">
            <SectionTitle
            title={<> Le<span> grand </span> stockage </>}
            />
             
             {(windowWidth >= 992) && <div className="wrap hidden miniLaptop:block">

              {(productData && productData?.length) ? <>
              {productData.map((wrapper: any[], index: number) => {
                return <div key={index} className="multi-category-wrapper">
                <>
                {wrapper ?<>
                  {wrapper.map((item: any, index: number) => {
                    if(index === 0)
                      return <BigStockPuBanner
                      key={index}
                      image={item?.image}
                      link={item?.link}
                      />
                    else return <ProductBigStockageCard
                    key={index}
                    product={item}
                    />
                  })}</>: <></>
                }
                </>
              </div>
              })}
              </>: <></>}
            </div>}

            {(windowWidth >= 570 && windowWidth < 992) && <div className="wrap hidden treffPunkTeSize:block miniLaptop:hidden ">
            {(productData && productData?.length) ? <div className="w-full grid grid-cols-3 multi-category-wrapper">
                  
                  {
                    productData.map((item: any, key: number) => {
                      if(key === 0 || key === 4 || key === 6 || key === 11)
                        return <BigStockPuBanner
                        key={key}
                        image={item?.image}
                        link={item?.link}
                        />
                      else return <ProductBigStockageCard
                      key={key}
                    product={item}
                    />
                    })
                  }
              </div>: <></>}
            </div>}

            {windowWidth <= 569 && <div className="wrap block treffPunkTeSize:hidden ">
            {(productData && productData?.length) ? <div className="w-full grid grid-cols-2 multi-category-wrapper">
                {
                    productData.map((item: any, key: number) => {
                      if(key === 0 || key === 3 || key === 6 || key === 9)
                        return <BigStockPuBanner
                        key={key}
                        image={item?.image}
                        link={item?.link}
                        />
                      else return <ProductBigStockageCard
                      key={key}
                    product={item}
                    />
                    })
                  }
              </div>: <></>}
            </div>}

            

          </div>
        </div>
  )
}

export default BigStockProductsList