import moment from 'moment';
import { AuthContext } from '../../../../context/auth';
import useOrder from '../../../../utils/utilities/hooks/useOrder';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react'
import { moneyFormating } from '../../../../utils/utilities/constants';
import { Link } from 'react-router-dom';

function Orders() {
  let { client } = useOrder()
  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  const [page, setPage] = useState(1);
  const [limit, _] = useState(10);
  const {
    data: orderData,
    isLoading
  } = useQuery({
    queryKey: ["user-orders-data", page, limit],
    queryFn: async () => {
      let result = await client.getUserOrders(sessionInfo?.userInfo?.email_address, { page, limit})
      return result;
    }
  });

  const getStatusLabel = (status: string) => {
    
    switch (status) {

      case 'pending':
        return 'En attente';
        break;

      case 'processing':
        return 'En traitement';
        break;

      case 'shipping':
        return 'En cours de livraison';
        break;

      case 'refund-request':
        return 'Processus de retour';
        break;

      case 'refunded':
        return 'Remboursé';
        break;

      case 'cancelled':
        return 'Annulé';
        break;
        
      case 'not-delivered':
        return 'Echec de livraison';
        break;
      case 'delivered':
        return 'Livré';
        break;
    
      default:
        break;
    }
  }
  return (
    <table className="orders-table MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table">
      <thead>
        <tr>
          <th className="orders-table__header orders-table__header-order-number"><span className="nobr">N° Commande</span></th>
          <th className="hidden tablet:block orders-table__header orders-table__header-order-date"><span className="nobr">Date</span></th>
          <th className="orders-table__header orders-table__header-order-status"><span className="nobr">Status</span></th>
          <th className="orders-table__header orders-table__header-order-total"><span className="nobr">Total</span></th>
          <th className="orders-table__header orders-table__header-order-actions"><span className="nobr">Actions</span></th>
        </tr>
      </thead>
      <tbody>
      {isLoading &&
          <tr>
            <td colSpan={5} className="orders-table__cell orders-table__cell-order-number">
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  padding: 24,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                ><span style={{color: '#263238'}}>Chargement des données ... </span>
                  
                </div>
            </td>
          </tr>
        }

          { orderData?.results?.length ?<>
                {orderData?.results?.map((order: any, index) => {
                  const totalQty= order?.cart.reduce(
                    (acc: any, item: any) => acc + item.qty,
                    0
                );
            return <tr key={index} className="orders-table__row orders-table__row--status-processing order">
          <td className="orders-table__cell orders-table__cell-order-number" data-title="N° Commande">
            <Link to={`/profile/orders/${order?._id}`}>
              		
              <p className='max-w-[50px] truncate text-ellipsis'>#{order?._id}</p>						
            </Link>
          </td>
          <td className="hidden tablet:block orders-table__cell orders-table__cell-order-date" data-title="Date">
            <time dateTime="2024-09-26T00:33:10+00:00">{moment(order?.created_at).format('DD MMMM YYYY hh:m:s')}</time>
          </td>
          <td className={`orders-table__cell font-bold orders-table__cell-order-status ${order?.status === 'cancelled' ? 'text-[#ee1111]' : order?.status === 'delivered' ? 'text-[#0a772d]' :'' } `} data-title="Status">
            {getStatusLabel(order?.status)}
          </td>
          <td className="orders-table__cell orders-table__cell-order-total" data-title="Total">
            <span className="Price-amount amount" >{moneyFormating(order?.total_price ?? 0)}</span> pour {totalQty} article(s)
          </td>
          <td className="orders-table__cell orders-table__cell-order-actions" data-title="Actions">
          <Link to={`/profile/orders/${order?._id}`} className="button wp-element-button button font-bold view">Détails</Link></td>
        </tr>})}
        </> : <tr>
        <td colSpan={5} className="orders-table__cell orders-table__cell-order-number">
                  
                    <div className="font-bold" style={{padding: "15px 0px", display: 'flex', justifyContent: 'center', alignItems: "center", color: '#263238'}}>Aucune données</div>
                  </td></tr>
              }
      </tbody>
    </table>

  )
}

export default Orders