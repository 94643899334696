import React, { useEffect, useState } from 'react'
import ProductFull from './----product-full-image'
import ProductThumbsSlider from './----product-thumbs-sider'

function ProductImages({images = []}: any) {
  const [activeImage, setActiveImage] = useState<any>(null)

  useEffect(() => {
    if(images?.length) {
      setActiveImage(images[0])
    }
  }, [images])
  return (
    <div className='--product-details-images' >
        <ProductFull
        image = {activeImage}
        />
        <ProductThumbsSlider
        images = {images}
        setActiveImage={(image: any) => setActiveImage(image)}
        />
    </div>
  )
}

export default ProductImages