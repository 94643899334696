import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../context/auth'
import React, { useContext } from 'react'

function Informations() {
  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  return (
    <>
      <p>
                  Salut <strong>{sessionInfo?.userInfo?.full_name}</strong></p>
                <p>
                  Dépuis votre profile vous pouvez voir vos récentes <Link to="/profile/orders" className='font-semibold text-[#ec2535]'  >commandes</Link> et les gérer, modifier <Link to="/profile/edit-account" className='font-semibold text-[#ec2535]' >vos informations</Link> et changer votre <Link to="/profile/edit-password" className='font-semibold text-[#ec2535]' >mot de passe</Link>.</p>
    </>
  )
}

export default Informations