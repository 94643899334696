import React, { useContext } from 'react'
import CustomReviewSar from '../../../--components/--custom-review-star'
import { AuthContext } from '../../../../context/auth'
import { Link } from 'react-router-dom'

function ProductReview({currentData}: any) {
    const { sessionInfo, setUserInfo } = useContext(AuthContext)
  return (
    <div id="reviews" className="woocommerce-Reviews">

                        <div id="comments">
                          {currentData?.reviews?.length ? <>
                            <ol className="commentlist">
                                <li className="review byuser comment-author-nguessanmarx even thread-even depth-1" id="li-comment-28">
                                <div id="comment-28" className="comment_container">
                                    <img alt="" src="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=60&d=mm&r=g" srcSet="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=120&d=mm&r=g 2x" className="avatar pull-left media-object avatar-60 photo" height={60} width={60} loading="lazy" decoding="async" />
                                    <div className="comment-text">
                                    <div className="star-rating" role="img" aria-label="Rated 4 out of 5"><span style={{width: '80%'}}>Rated <strong className="rating">4</strong> out of 5</span></div><span className="hidden" itemProp="itemReviewed" itemScope itemType="http://schema.org/Thing">
                                        <span itemProp="name">Donec vel mauris</span>
                                    </span>
                                    <p className="meta">
                                        <em className="woocommerce-review__awaiting-approval">
                                        Your review is awaiting approval		</em>
                                    </p>
                                    <div className="description"><p>hello<br />
                                        hhf</p>
                                    </div>
                                    </div>
                                </div>
                                </li>{/* #comment-## */}
                                <li className="review byuser comment-author-nguessanmarx odd alt thread-odd thread-alt depth-1" id="li-comment-29">
                                <div id="comment-29" className="comment_container">
                                    <img alt="" src="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=60&d=mm&r=g" srcSet="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=120&d=mm&r=g 2x" className="avatar pull-left media-object avatar-60 photo" height={60} width={60} loading="lazy" decoding="async" />
                                    <div className="comment-text">
                                    <div className="star-rating" role="img" aria-label="Rated 4 out of 5"><span style={{width: '80%'}}>Rated <strong className="rating">4</strong> out of 5</span></div><span className="hidden" itemProp="itemReviewed" itemScope itemType="http://schema.org/Thing">
                                        <span itemProp="name">Donec vel mauris</span>
                                    </span>
                                    <p className="meta">
                                        <em className="woocommerce-review__awaiting-approval">
                                        Your review is awaiting approval		</em>
                                    </p>
                                    <div className="description"><p>pooe</p>
                                    </div>
                                    </div>
                                </div>
                                </li>{/* #comment-## */}
                            </ol>
                          </>: <>
                            <p className="woocommerce-noreviews">Il n'y a pas encore de commentaires.</p>
                          </>}
                          
                        </div>

                        <div id="review_form_wrapper">
                          <div id="review_form">
                            <div id="respond" className="comment-respond">
                            {currentData?.reviews?.length === 0 ? <span id="reply-title" className="comment-reply-title">Soyez le premier à commenter dans vos commandes.</span>: <></>}
                              {!sessionInfo?.userInfo && <p className="mt-2 must-log-in">Vous devez <Link to="/login" className='font-bold text-[#001c96]'>être connecté</Link>, puis avoir commandé ce produit, afin de poster un commentaire.</p>}	
                              
                              {/* {sessionInfo?.userInfo && <form action="" method="post" id="commentform" className="comment-form">
                                <div className="comment-form-rating flex items-center"><label htmlFor="rating">Your rating&nbsp;<span className="required">*</span></label>
                                  <p className="stars selected">
                                  <CustomReviewSar/>
                                  </p>
                                  
                                </div>
                                <p className="comment-form-comment">
                                  <label htmlFor="comment">Your review&nbsp;<span className="required">*</span></label>
                                  <textarea id="comment" name="comment" className='resize-none' cols={45} rows={8} required defaultValue={""} />
                                </p>
                                <p className="form-submit">
                                  <input name="submit" type="submit" id="submit" className="submit" defaultValue="Submit" /> 
                                </p>
                              </form>} */}

                            </div>{/* #respond */}
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
  )
}

export default ProductReview