import SectionTitle from '../../../--partials/--section-title'
import React, { useState } from 'react'
import useProduct from '../../../../utils/utilities/hooks/useProduct'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import ProductBigStockageCard from '../../../../layouts/--components/--product-big-stockage-card';

function FullProductsList() {
  let { client } = useProduct()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(20);
  const [meta, setMeta] = useState<any> (null)
  const [productData, setProductData] = useState<any[]> ([])

  const {  data, isLoading, isFetching, isError, fetchNextPage, hasNextPage}: any =
  useInfiniteQuery({
        queryKey: ['full-products-data', page, limit],
        queryFn: async ({ pageParam}: any) => {
            
             const data = await client.getFullProducts({ page: pageParam, limit})

            setMeta({
              hasNextPage : data?.hasNextPage,
              hasPrevPage : data?.hasPrevPage,
              limit : data?.limit,
              nextPage : data?.nextPage,
              page : data?.page,
              pagingCounter : data?.pagingCounter,
              prevPage : data?.prevPage,
              totalDocs : data?.totalDocs,
              totalPages : data?.totalPages
            })

            setProductData([...productData, ...data.results])
            
          return data.results
        },
        initialPageParam: 1,
        getNextPageParam(lastPage: any, allPages: any) {
          return lastPage?.length > 0 ? allPages?.length + 1 : undefined;
        },
    })

  return (
    <div className="flex justify-center w-full --full-products-list mt-5">
          <div className="wrapper">
          <SectionTitle
            title={<> <span> Explorez </span> nos produits </>}
            />
             <div className="wrap">
                {(data && productData?.length) ? <div className="multi-category-wrapper">
                {
                    productData.map((item: any, key: number) => {
                      return <ProductBigStockageCard
                      key={key}
                    product={item}
                    />
                    })
                  }
                  
                </div>: <></>}
                
              </div>

              <div className="flex w-full items-center justify-center --load-more">

                {meta?.hasNextPage && <button type='button' className="button"
                onClick={(e: any) => {
                  e.preventDefault()
                  fetchNextPage()
                }}
                >
                 {isFetching ? 'Chargement...' : 'Chargez plus '} 
                </button >}
              </div>

          </div>
      </div>
  )
}

export default FullProductsList