import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import useOnClickOutSide from '../../utils/utilities/onClickOutSide';
import React, { useContext, useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query';
import userUser from '../../utils/utilities/hooks/useAuth';
import toast from 'react-hot-toast';
import useWindowSize from '../../utils/utilities/useWindowSize';
interface MenuListProp {
  setOpenMobileMenu?: Function
}
function HeaderAccount({setOpenMobileMenu}: MenuListProp) {
  
  const [windowWidth, _] = useWindowSize()
  const accountDropdownRef = useRef<any>(null)
  const navigate = useNavigate()

  const [showAccountDropdown, setShowAccountDropdown] = useState<boolean>(false);
  useOnClickOutSide(accountDropdownRef, () => setShowAccountDropdown(false))

  const { sessionInfo, setUserInfo, signOut } = useContext(AuthContext)
  let { client } = userUser()
  const logoutHandler = () => {
    mutation.mutate()
  };

  const mutation = useMutation({
    mutationFn: async () => {
      return await signOut();
      
    },
    onSuccess: (data, variables, context) => {
      toast("Triste de vous voir déconnecté !", {
        duration: 4000,
        position: 'top-center',
      
        // Styling
        style: {
          fontSize: 16,
          fontWeight: 700,
          border: `2px solid #0E31BBFF`
        },
        className: '',
      
        // Custom Icon
        icon: '🥺',
      
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      setTimeout(() => {
          navigate('/login')
      }, 500);
    },
    onError: (data: any) => {
      toast.error(data ? data?.message : `Une erreur est survenue` , {position: 'bottom-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #BF153AFF` },})
    },
  });
  return (
    <button ref={accountDropdownRef}  type='button' className="--my-account relative">
        <p className='flex items-center gap-x-[5px]' onClick={() => setShowAccountDropdown(!showAccountDropdown)}  >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                <path d="M15 5C15 6.65685 13.2418 8.5 12 8.5C10.7582 8.5 9 6.65685 9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5Z" stroke="currentColor" strokeWidth="1.5" />
                <path d="M16.0415 9C17.5645 10.3353 18.5513 12.5969 17.6651 14.7052C17.4742 15.1594 17.0361 15.4539 16.5514 15.4539C16.0585 15.4539 15.2489 15.296 15.0917 15.9374L13.9944 20.4123C13.7656 21.3454 12.9433 22 12 22C11.0567 22 10.2344 21.3454 10.0056 20.4123L8.90833 15.9374C8.75103 15.296 7.94149 15.4539 7.44862 15.4539C6.9639 15.4539 6.52582 15.1594 6.33488 14.7052C5.44866 12.5969 6.43558 10.3353 7.95857 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            <span>{sessionInfo?.userInfo ? `${sessionInfo?.userInfo?.full_name}` : ' Mon compte'}</span>
            {/* <p>M. N'Guessan</p> */}
        </p>
        
        {windowWidth > 649 && showAccountDropdown && <div className="--account-dropdown absolute top-[50px] right-0 z-[100]">
            {sessionInfo?.userInfo ?<>
                <button 
                     onClick={() => navigate('/profile')}
                    type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
                    </svg>
                    Mon profile
                </button>
                <button 
                onClick={() => navigate('/wishlist')}
                type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
                    </svg>
                    Mes favoris</button>
                <button 
                onClick={() => navigate('/profile/orders')}
                type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M6 5v1H4.667a1.75 1.75 0 0 0-1.743 1.598l-.826 9.5A1.75 1.75 0 0 0 3.84 19H16.16a1.75 1.75 0 0 0 1.743-1.902l-.826-9.5A1.75 1.75 0 0 0 15.333 6H14V5a4 4 0 0 0-8 0Zm4-2.5A2.5 2.5 0 0 0 7.5 5v1h5V5A2.5 2.5 0 0 0 10 2.5ZM7.5 10a2.5 2.5 0 0 0 5 0V8.75a.75.75 0 0 1 1.5 0V10a4 4 0 0 1-8 0V8.75a.75.75 0 0 1 1.5 0V10Z" clipRule="evenodd" />
                    </svg>

                    Mes commandes</button>
                <button 
                 onClick={logoutHandler}
                type='button'className='flex items-center gap-x-[5px]'>  
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75V4.25Z" clipRule="evenodd" />
                        <path fillRule="evenodd" d="M19 10a.75.75 0 0 0-.75-.75H8.704l1.048-.943a.75.75 0 1 0-1.004-1.114l-2.5 2.25a.75.75 0 0 0 0 1.114l2.5 2.25a.75.75 0 1 0 1.004-1.114l-1.048-.943h9.546A.75.75 0 0 0 19 10Z" clipRule="evenodd" />
                    </svg>
                    Déconnexion
                </button>
            </> : <>
            <button 
            onClick={() => navigate('/login')}
            type='button'className='flex items-center gap-x-[5px]'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M6.111 11.89A5.5 5.5 0 1 1 15.501 8 .75.75 0 0 0 17 8a7 7 0 1 0-11.95 4.95.75.75 0 0 0 1.06-1.06Z" />
                    <path d="M8.232 6.232a2.5 2.5 0 0 0 0 3.536.75.75 0 1 1-1.06 1.06A4 4 0 1 1 14 8a.75.75 0 0 1-1.5 0 2.5 2.5 0 0 0-4.268-1.768Z" />
                    <path d="M10.766 7.51a.75.75 0 0 0-1.37.365l-.492 6.861a.75.75 0 0 0 1.204.65l1.043-.799.985 3.678a.75.75 0 0 0 1.45-.388l-.978-3.646 1.292.204a.75.75 0 0 0 .74-1.16l-3.874-5.764Z" />
                </svg>
                Connexion
            </button>
            <button  onClick={() => navigate('/register')} type='button'className='flex items-center gap-x-[5px]'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 0 0-1.588-3.755 4.502 4.502 0 0 1 5.874 2.636.818.818 0 0 1-.36.98A7.465 7.465 0 0 1 14.5 16Z" />
                </svg>
                Inscription
            </button>
            </>}
            
        </div>}

        {windowWidth <= 649 && <div className="--account-dropdown">
            {sessionInfo?.userInfo ?<>
                <button 
                     onClick={() => {
                      if(setOpenMobileMenu) {
                        setOpenMobileMenu(false)
                      }
                      navigate('/profile')
                    }}
                    type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path d="M10 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.465 14.493a1.23 1.23 0 0 0 .41 1.412A9.957 9.957 0 0 0 10 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 0 0-13.074.003Z" />
                    </svg>
                    Mon profile
                </button>
                <button 
                onClick={() => {
                  if(setOpenMobileMenu) {
                    setOpenMobileMenu(false)
                  }

                  navigate('/wishlist')
                }}
                type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
                    </svg>
                    Mes favoris</button>
                <button 
                onClick={() => {
                  if(setOpenMobileMenu) {
                    setOpenMobileMenu(false)
                  }
                  navigate('/profile/orders')
                }}
                type='button'className='flex items-center gap-x-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path fillRule="evenodd" d="M6 5v1H4.667a1.75 1.75 0 0 0-1.743 1.598l-.826 9.5A1.75 1.75 0 0 0 3.84 19H16.16a1.75 1.75 0 0 0 1.743-1.902l-.826-9.5A1.75 1.75 0 0 0 15.333 6H14V5a4 4 0 0 0-8 0Zm4-2.5A2.5 2.5 0 0 0 7.5 5v1h5V5A2.5 2.5 0 0 0 10 2.5ZM7.5 10a2.5 2.5 0 0 0 5 0V8.75a.75.75 0 0 1 1.5 0V10a4 4 0 0 1-8 0V8.75a.75.75 0 0 1 1.5 0V10Z" clipRule="evenodd" />
                    </svg>

                    Mes commandes</button>
                <button 
                 onClick={() => {
                  if(setOpenMobileMenu) {
                    setOpenMobileMenu(false)
                  }

                  logoutHandler()
                  
                }}
                type='button'className='flex items-center gap-x-[5px]'>  
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M3 4.25A2.25 2.25 0 0 1 5.25 2h5.5A2.25 2.25 0 0 1 13 4.25v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 0-.75-.75h-5.5a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-2a.75.75 0 0 1 1.5 0v2A2.25 2.25 0 0 1 10.75 18h-5.5A2.25 2.25 0 0 1 3 15.75V4.25Z" clipRule="evenodd" />
                        <path fillRule="evenodd" d="M19 10a.75.75 0 0 0-.75-.75H8.704l1.048-.943a.75.75 0 1 0-1.004-1.114l-2.5 2.25a.75.75 0 0 0 0 1.114l2.5 2.25a.75.75 0 1 0 1.004-1.114l-1.048-.943h9.546A.75.75 0 0 0 19 10Z" clipRule="evenodd" />
                    </svg>
                    Déconnexion
                </button>
            </> : <>
            <button 
            onClick={() => {
              if(setOpenMobileMenu) {
                setOpenMobileMenu(false)
              }

              navigate('/login')
            }}
            type='button'className='flex items-center gap-x-[5px]'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M6.111 11.89A5.5 5.5 0 1 1 15.501 8 .75.75 0 0 0 17 8a7 7 0 1 0-11.95 4.95.75.75 0 0 0 1.06-1.06Z" />
                    <path d="M8.232 6.232a2.5 2.5 0 0 0 0 3.536.75.75 0 1 1-1.06 1.06A4 4 0 1 1 14 8a.75.75 0 0 1-1.5 0 2.5 2.5 0 0 0-4.268-1.768Z" />
                    <path d="M10.766 7.51a.75.75 0 0 0-1.37.365l-.492 6.861a.75.75 0 0 0 1.204.65l1.043-.799.985 3.678a.75.75 0 0 0 1.45-.388l-.978-3.646 1.292.204a.75.75 0 0 0 .74-1.16l-3.874-5.764Z" />
                </svg>
                Connexion
            </button>
            <button onClick={() => {
                  if(setOpenMobileMenu) {
                    setOpenMobileMenu(false)
                  }

                  navigate('/register')
                }} type='button'className='flex items-center gap-x-[5px]'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 0 0-1.588-3.755 4.502 4.502 0 0 1 5.874 2.636.818.818 0 0 1-.36.98A7.465 7.465 0 0 1 14.5 16Z" />
                </svg>
                Inscription
            </button>
            </>}
            
        </div>}
    </button>
  )
}

export default HeaderAccount