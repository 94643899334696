import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import RQProviders from './utils/providers/ReactQueryProvider';
import reportWebVitals from './reportWebVitals';
import { store } from './utils/redux/store';
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App';
import { Toaster } from 'react-hot-toast';
import './index.css';
import './assets/--styles/styles.scss';
import "react-multi-carousel/lib/styles.css";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <RQProviders>
      <Router>
        <App />
        <Toaster />
      </Router>
    </RQProviders>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
