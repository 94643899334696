import { Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom'
import SideBestSeller from '../../--partials/--best-seller'
import SideCategories from '../../--partials/--side-categories'
import Filter from '../../--partials/--filter'
import ProductBigCardTwo from '../../--components/--product-big-card-two'
import EmptyIllustration from '../../--partials/--empty-illustration'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Commet } from 'react-loading-indicators';
import { Product } from '../../../sdks/product-v1/utils/DataSchemas';
import { moneyFormating } from '../../../utils/utilities/constants';
import { Link } from 'react-router-dom';
import useProduct from '../../../utils/utilities/hooks/useProduct';
import { useState } from 'react'
let timer: any = null
function SearchPage() {

  let { client } = useProduct()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(20);
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("q");
  const [productData, setProductData] = useState<any[]> ([])
  const [meta, setMeta] = useState<any> (null)

  const {  data, isLoading, isFetching, isError, fetchNextPage, hasNextPage}: any =
  useInfiniteQuery({
        queryKey: ['full-search-products-data', page, limit, searchText],
        queryFn: async ({ pageParam}: any) => {
            
             if(searchText) {
              const data = await client.searchProduct({ page, limit, search: searchText ?? ''})

                setMeta({
                  hasNextPage : data?.hasNextPage,
                  hasPrevPage : data?.hasPrevPage,
                  limit : data?.limit,
                  nextPage : data?.nextPage,
                  page : data?.page,
                  pagingCounter : data?.pagingCounter,
                  prevPage : data?.prevPage,
                  totalDocs : data?.totalDocs,
                  totalPages : data?.totalPages
                })

                setProductData([...productData, ...data.results])
                
              return data.results
             }
        },
        initialPageParam: 1,
        getNextPageParam(lastPage: any, allPages: any) {
          return lastPage?.length > 0 ? allPages?.length + 1 : undefined;
        },
    })
  return  <div className='--product-details w-full flex justify-center' >
  <div className="wrapper flex flex-col-reverse width650:flex-row gap-x-[25px] ">
  <div className="--product-details--left-section block width500:flex justify-center gap-x-5 width650:block">
      <SideCategories/>
      <SideBestSeller/>
    </div>

    <div className="--product-details--right-section">

    <div className="flex justify-center w-full --best-seller-products-list">
    <div className="wrapper-inner w-full">
    <div className="w-full flex flex-col smallLaptop:flex-row items-start smallLaptop:justify-between">
              <div className="search-result">({meta?.totalDocs ?? 0} résultats)</div>
              
          </div>
          {(data && productData.length) ? <div className="w-full mt-3 bg-[white] rounded-[5px] p-[15px] grid grid-cols-1 smallScreen:grid-cols-2 width650:grid-cols-1 smallLaptop:grid-cols-2 gap-x-[10px] bigLaptop:gap-x-[25px] gap-y-[20px] ">
          {
              productData.map((item: any, key: number) => {
                return <ProductBigCardTwo
                key={key}
              product={item}
              />
              })
            }
            
          </div>: <EmptyIllustration/>}
      
          <div className="flex w-full items-center justify-center --load-more">
              {meta?.hasNextPage && <button type='button' className="button"
              onClick={(e: any) => {
                e.preventDefault()
                fetchNextPage()
              }}
              >
              {isFetching ? 'Chargement...' : 'Chargez plus '} 
              </button >}
            </div>
      </div>
    </div>
    </div>
  </div>
  
</div>
}

export default SearchPage