import useNewsletter from '../../utils/utilities/hooks/useNewsletter';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react'
import toast from 'react-hot-toast';

function Newsletter() {
    let { client } = useNewsletter()
    const [email_address, setEmail] = useState("");
    const mutation = useMutation({
        mutationFn: async () => {
            return await client.createNewsletter({
                email_address,
                is_activated: true
              });
        },
        onSuccess: () => {
          toast.success( 'Vous aves souscrit à notre newsletter avec succès !', {position: 'top-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #1DC953FF` },})
          setEmail('')
          
        },
        onError: (data: any) => {
          toast.error(data ? data?.message : `Une erreur est survenue` , {position: 'top-center', style: {fontSize: 16, fontWeight: 700, border: `2px solid #BF153AFF` },})
          setEmail('')
        },
      });
  return (
    <div className="--newsletter flex justify-center">
        <div className="wrapper">	   
            <div id="text-18" className="widget text-18 widget_text pull-left">
                <div className="widget-inner">			
                    <div className="textwidget">
                        <div className="need-help">
                            <h3>BESOIN D'AIDE ? APPELEZ NOUS QUAND VOUS VOULEZ</h3>
                            NOTRE EQUIPE EST DISPONIBLE 24/7 AU (00225) 272-024-3418
                        </div>
                    </div>
                </div>
            </div>
            <div id="text-19" className="widget text-19 widget_text pull-right">
                <div className="widget-inner">
                    <div className="textwidget">
                        <form id="mc4wp-form-1" className="mc4wp-form mc4wp-form-197" method="post" data-id={197} data-name>
                            <div className="mc4wp-form-fields">
                                    <div className="newsletter-content">
                                        <input type="email" 
                                        id="mc4wp_email" 
                                        className="newsletter-email" 
                                        name="EMAIL" 
                                        value={email_address}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Veuillez entrer votre adresse e-mail" required />
                                        <input className="newsletter-submit cursor-pointer" type="button" 
                                        disabled={mutation?.isPending
                                            ||
                                            (
                                              email_address.trim() === ""
                                            )
                                        }
                                        onClick={() => !mutation.isPending && mutation.mutate()}
                                        defaultValue={mutation?.isPending ? 'En cours...' : "Souscrire"} />
                                    </div>
                            </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Newsletter