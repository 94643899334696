import React from 'react'
import { Link } from 'react-router-dom'
interface bannerProp {
  image: any,
  link: string
}
function BigStockPuBanner({image, link}: bannerProp) {
  return (
    <Link to={link} className="mcategory-item item-thumb">
        <img width={480} height={570} src={image?.url} className="attachment-full size-full" alt="" />				
    </Link>
  )
}

export default BigStockPuBanner