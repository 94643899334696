import { Link } from 'react-router-dom';
import { Banner } from '../../sdks/banner-v1/utils/DataSchemas';
import useBanner from '../../utils/utilities/hooks/useBanner';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'

function PubFullBanner() {

  let { client } = useBanner()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(4);

  const {
    data: bannerData,
    isLoading
  } = useQuery({
    queryKey: ["banners-data", page, limit],
    queryFn: async () => {
      let result = await client.getAllBanners({ page, limit})
      return result;
    }
  });
  return (
    <div className="flex justify-center w-full">
    <div className="wrapper">
        <div className='--pup-three-banners flex'>

          {(bannerData && bannerData.results.length) ? <figure className="--pup-three-banner-big">
            <a href={bannerData ? bannerData.results.find((banner: Banner) => banner.position === 'first_bann_left')?.link : '/'} target="_self" className="vc_single_image-wrapper   vc_box_border_grey">
              <img className="vc_single_image-img " src={bannerData && bannerData.results.find((banner: Banner) => banner.position === 'first_bann_left')?.image.url} width={786} height={270} alt="banner-id4" title="banner-id4" />
            </a>
          </figure>: <></>}

          {(bannerData && bannerData.results.length) ? <figure className="--pup-three-banner-small">
            <a href={bannerData ? bannerData.results.find((banner: Banner) => banner.position === 'first_bann_left')?.link : '/'} target="_self" className="vc_single_image-wrapper   vc_box_border_grey">
              <img className="vc_single_image-img " src={bannerData && bannerData.results.find((banner: Banner) => banner.position === 'firts_bann_right')?.image.url} width={385} height={270} alt="banner2-id4" title="banner2-id4" />
            </a>
          </figure>: <></>}
        </div>
    </div>
  </div>
  )
}

export default PubFullBanner