import React, { useState } from 'react'
import ProductReview from './----product-review'
function ProductTabs({currentData}: any) {

    const [activeTab, setActiveTab] = useState<number>(0)
  return (
    <div className="tabbable">
                  {/* <ul className="nav nav-tabs">
                    <li className={`description_tab ${activeTab === 0 ? 'active': ''}`}>
                      <a href="#tab-description" 
                      onClick={(e: any) => {
                        e.preventDefault()
                        setActiveTab(0)
                      }}
                      data-toggle="tab">Description</a>
                    </li>
                    <li className={`reviews_tab ${activeTab === 1 ? 'active': ''}`} >
                      <a 
                      onClick={(e: any) => {
                        e.preventDefault()
                        setActiveTab(1)
                      }}
                      href="#tab-reviews" data-toggle="tab">Avis ({currentData?.reviews?.length})</a>
                    </li>
                  </ul> */}
                  <div className="clear" />
                  <div className=" tab-content">
                    <div className={`tab-pane ${activeTab === 0 ? 'active': ''}`} id="tab-description">
                    {currentData?.description && <p dangerouslySetInnerHTML={{__html: currentData.description}} />}
                    </div>
                    <div className={`tab-pane ${activeTab === 1 ? 'active': ''}`} id="tab-reviews">
                      <ProductReview currentData = {currentData}/>
                    </div>
                  </div>
                </div>
  )
}

export default ProductTabs