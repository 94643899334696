import Carousel from 'react-multi-carousel'
import ProductBigCard from '../../../--components/--product-big-card'
import SectionTitle from '../../../--partials/--section-title'
import React, { useState } from 'react'
import useProduct from '../../../../utils/utilities/hooks/useProduct'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../../../../sdks/product-v1/utils/DataSchemas'

function BestSellerProductsList() {

  let { client } = useProduct()

  const [page, setPage] = useState(1);

  const {
    data: productData,
    isLoading
  } = useQuery({
    queryKey: ["best-seller-products-data", page],
    queryFn: async () => {
      const data = await client.getBestSellerProducts({ page, limit: 18})
      return data.results.reduce((acc: any, _: any, i: number) => {
        if (i % 6 === 0) acc.push(data.results.slice(i, i + 6));
        return acc;
      }, []); 
    }
  });
  
  return (
    <div className="flex justify-center w-full --best-seller-products-list">
        <div className="wrapper">
          <SectionTitle
          title={<> <span>Meilleures</span> ventes </>}
          />
            {(productData && productData?.length) ? <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3001 },
                  items: 1,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1025 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 465 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              showDots={false}
              swipeable={true}
              draggable={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="transform 2000ms ease-in-out"
              transitionDuration={5000}
              partialVisible={false}
            >

              {
                productData.map((wrapper: any, key: number) => {
                  return <div key={key} className="w-full grid grid-cols-1 width650:grid-cols-2 width1100:grid-cols-3 gap-x-[25px] gap-y-[20px] ">
                  <>
                    {wrapper ?<>
                      {wrapper.map((item: Product, index: number) => {
                        return <ProductBigCard key={index} product={item}/>
                      })}</>: <></>
                    }
                </>
              </div>
                })
              }
              
            </Carousel> : <></>}
        </div>
      </div>
  )
}

export default BestSellerProductsList