import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface HeaderState {
    cart: Array<any>
}


// Define the initial state using that type
const initialState: HeaderState = {
    cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems") ?? '')
    : [],
}

export const titleSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<any>) => {
            const item = action.payload;
            const isItemExist = state.cart.find((i: any) => i._id === item._id);
            if (isItemExist) {
                localStorage.setItem("cartItems", JSON.stringify(state.cart.map((i: any) => (i._id === isItemExist._id ? item : i)),));
                return {
                    ...state,
                    cart: state.cart.map((i: any) => (i._id === isItemExist._id ? item : i)),
                };
            } else {
                localStorage.setItem("cartItems", JSON.stringify([...state.cart, item]));
                return {
                    ...state,
                    cart: [...state.cart, item],
                };
            }
        },
        removeFromCart: (state, action) => {
            localStorage.setItem("cartItems", JSON.stringify(state.cart.filter((i) => i._id !== action.payload)));
            return {
              ...state,
              cart: state.cart.filter((i) => i._id !== action.payload),
            };
        },

        emptyCart: (state) => {
            localStorage.removeItem('cartItems');
            return {
              ...state,
              cart: [],
            };
        },
    }
})

export const { addToCart, removeFromCart, emptyCart} = titleSlice.actions


export default titleSlice.reducer
