import axios, { AxiosRequestConfig, Method } from 'axios';
import { Options } from './DataSchemas';
import noResponseError from './error';
import { API_SERVER } from '../utils/utilities/constants';
import { store } from '../utils/redux/store';
const dispatch = store.dispatch

axios.defaults.withCredentials = true
const request = async (method: string, path: string, options: Options, withCredentials: boolean = true) => {
    const requestOptions: AxiosRequestConfig = {
        headers: options.headers,
        baseURL: API_SERVER,
        timeout: options.timeout,
        method: method as Method,
        url: path,
        data: options.data,
        withCredentials: withCredentials
    };

    axios.interceptors.response.use(
        function (response: any) {
          return response
        },
        async function (error: any) {
    
          try {
            if (
              error?.response?.status === 403
              //&& error.response.data?.detail === 'Token expired'
            ) {
    
              const originalRequest = {...error.config}
              if (originalRequest && originalRequest.url !== "/token") {
    
                originalRequest.baseURL = `${API_SERVER}/user`
                originalRequest.method = "POST"
                originalRequest.data = ""
                originalRequest.url = "/token"
                 await axios(originalRequest)
                 return axios(error.config)
    
              } else {
                if (options && options.signOut) {
                  return await options?.signOut()
                }
              }
            }
    
            if (
              error?.response?.status === 401
              //&& error.response.data?.detail === 'Not authenticated'
            ){
              if (options && options.signOut) {
                return await options?.signOut()
              }
            }
    
            return Promise.reject(error)
          } catch (error) {
            console.log(error)
            return Promise.reject(error)
          }
        }
      )
      return axios(requestOptions).then(
        (response) => response.data,
        (err) => errorHanding(err)
    )
};

function errorHanding(err: any) {
    if (
    err.response !== null &&
    err.response !== undefined &&
    err.response.data !== null
    ) {
    return Promise.reject(err.response.data)
    }
    const errorMessages: any[] = []
    errorMessages.push(noResponseError(err))
    return Promise.reject({errors: errorMessages})
}

export default request;