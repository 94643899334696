import { Link } from 'react-router-dom';
import { Banner } from '../../sdks/banner-v1/utils/DataSchemas';
import useBanner from '../../utils/utilities/hooks/useBanner';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'

function PubSmallBanner() {

  let { client } = useBanner()

  const [page, setPage] = useState(1);
  const [limit, _] = useState(4);

  const {
    data: bannerData,
    isLoading
  } = useQuery({
    queryKey: ["banners-data", page, limit],
    queryFn: async () => {
      let result = await client.getAllBanners({ page, limit})
      return result;
    }
  });

  return (
    <div className="flex justify-center w-full">
      <div className="wrapper">
        {(bannerData && bannerData.results.length) ?  <div className="--small-banner">
            <img width={"100%"} height={163} src={bannerData && bannerData.results.find((banner: Banner) => banner.position === 'full_bann')?.image.url} className="attachment-larges size-larges" alt="" />			
        </div>: <></>}
      </div>
    </div>
  )
}

export default PubSmallBanner