import SideCategories from '../../--partials/--side-categories'
import SideBestSeller from '../../--partials/--best-seller'
import React, { useState } from 'react'
import ProductImages from './----partials/----product-images'
import ProductTopDetails from './----partials/----product-top-details'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import useProduct from '../../../utils/utilities/hooks/useProduct'
import { Product } from '../../../sdks/product-v1/utils/DataSchemas'
import ProductTabs from './----partials/----product-tabs'
import RelatedProductsList from '../../../layouts/--partials/--related-products-list'

function ProductDetails() {
  let { client } = useProduct()
  const [currentData, setCurrentData] = useState<Product>();
  const {slug} = useParams()
  useQuery({
    queryKey: ['product-data', slug],
    queryFn: async () => {
        if(slug) {
          let result = await client.getProductById(slug)
          await client.viewProduct(slug)
          setCurrentData(result)
        }
    }
  })
  return (
    <div className='--product-details w-full flex justify-center' >
        <div className="wrapper flex flex-col-reverse width650:flex-row gap-x-[25px] ">
          <div className="--product-details--left-section block width500:flex justify-center gap-x-5 width650:block">
            <SideBestSeller/>
            <SideCategories/>
          </div>

          <div className="--product-details--right-section">
              <div className="--product-top-infos flex flex-col miniLaptop:flex-row gap-y-5 miniLaptop:gap-y-0 ">
                <ProductImages
                  images = {currentData?.images}
                />
                <ProductTopDetails
                currentData = {currentData}
                />
              </div>

              <div className="--product-bottom-infos">
                <ProductTabs currentData = {currentData}/>
              </div>

              <RelatedProductsList currentData = {currentData}/>
          </div>
        </div>
        
    </div>
  )
}

export default ProductDetails